export default async function SendMetric(myMetric) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: myMetric,
  };

  try {
    const response = await fetch(
      "https://api.workobot.com/dev/planotipo",
      requestOptions
    );
    /* const json =  */ await response.json();
    //console.log(json);
    // return json;
  } catch (error) {
    console.error("Catch Error: ", error);
    // return null;
  }
}
